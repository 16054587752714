.Letter {
    opacity: 0;
}
.Letter--revealed {
    opacity: 1;
    /*animation: letterShrink forwards 0.1s;*/
}

/*@keyframes letterShrink {*/
    /*!*from { transform: scale3d(1.5, 1.5, 1)}*!*/
    /*!*to { transform: scale3d(1, 1, 1)}*!*/
    /*from { opacity: 0; transform: translate3d(0, -25%, 0)}*/
    /*to { opacity: 1; transform: translate3d(0, 0, 0)}*/
/*}*/