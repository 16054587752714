* {
  box-sizing:border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Dosis', sans-serif;
}

html, body {
  height: 100vh;
  overflow:hidden;
}

* {
  -webkit-touch-callout: none;
  /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;
  /* prevent webkit from resizing text to fit */
  /* make transparent link selection, adjust last value opacity 0 to 1.0 */
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  /* prevent copy paste, to allow, change 'none' to 'text' */
  -webkit-tap-highlight-color: transparent;
}

/* Give input back to Auth Form */
.AuthForm, .AuthForm * {
  -webkit-user-select: initial;
}
/* and the Set Member Name form */
.SetMemberNameBox, .SetMemberNameBox * {
  -webkit-user-select: initial;
}


.PauseMenu__hover__memberName:hover {
  background: #e8ecf1;
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes blink {
  to { visibility: hidden }
}

@keyframes iconFloat {
  0% { transform: translateY(-27%); }
  100% { transform: translateY(0%); }
}