@keyframes characterWalkDown {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes characterWalkRight {
    0% {
        transform: translate3d(0, -25%, 0);
    }
    100% {
        transform: translate3d(-100%, -25%, 0);
    }
}

@keyframes characterWalkUp {
    0% {
        transform: translate3d(0, -50%, 0);
    }
    100% {
        transform: translate3d(-100%, -50%, 0);
    }
}

@keyframes characterWalkLeft {
    0% {
        transform: translate3d(0, -75%, 0);
    }
    100% {
        transform: translate3d(-100%, -75%, 0);
    }
}

/* JUMP */
@keyframes characterJump {
    /* NOTE: Add space at the end where nothing happens. This enables some time afterwards */
    0% {
        transform: translate3d(0, 0, 0);
    }
    20% {
        transform: translate3d(0, -20%, 0);
    }
    50% {
        transform: translate3d(0, -25%, 0);
    }
    55% {
        transform: translate3d(0, -27%, 0);
    }

    100% {
        transform: translate3d(0, 0, 0);
    }
}

@keyframes floatyIconBounce {
    0% {
        transform: translate3d(0, 0, 0);
    }
    100% {
        transform: translate3d(0, 12%, 0);
    }
}

@keyframes hackerFlicker {
    /*0% {*/
        /*opacity: 1;*/
    /*}*/
    /*50% {*/
        /*opacity: 0*/
    /*}*/
}
