
.mobile-dpad {
    position: fixed;
    width: 120px;
    height: 120px;
    pointer-events: none;
    /*z-index: 99;*/ /* Can we do this only when there are no interaction events live? */
    /* Maybe this rule should move to JS. Not sure I want a global body class */
}

.mobile-dpad.dpad-active .dot {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
}

.mobile-dpad .circle {
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 60px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
    z-index: 12;
    pointer-events: none;
    opacity:0;
}

.mobile-dpad .hitbox {
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    pointer-events: initial;
}

.mobile-dpad .hitbox.hb-active {
    visibility: visible;
}

/* OLD WAY */
/*.mobile-dpad .hitbox.hb-active svg path {*/
    /*fill: rgba(255, 255, 255, 0.6);*/
/*}*/
.mobile-dpad .hitbox.hb-active svg .DpadArrow--Fill {
    stroke: rgba(255,255,255,0.6);
}





.mobile-dpad .hitbox.hb-up {
    top: 0;
    left: 50%;
    margin-left: -20px;
}

.mobile-dpad .hitbox.hb-up svg {
    left: 50%;
    -webkit-transform: rotate(180deg) translateX(50%);
    transform: rotate(180deg) translateX(50%);
}

.mobile-dpad .hitbox.hb-down {
    bottom: 0;
    left: 50%;
    margin-left: -20px;
}

.mobile-dpad .hitbox.hb-down svg {
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.mobile-dpad .hitbox.hb-left {
    top: 50%;
    left: 0;
    margin-top: -20px;
}

.mobile-dpad .hitbox.hb-left svg {
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.mobile-dpad .hitbox.hb-right {
    top: 50%;
    right: 0;
    margin-top: -20px;
}

.mobile-dpad .hitbox.hb-right svg {
    right: 0;
    top: 50%;
    -webkit-transform: rotate(180deg) translateY(50%);
    transform: rotate(180deg) translateY(50%);
}

.mobile-dpad .hitbox svg {
    position: absolute;
}