* {
  box-sizing:border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Dosis', sans-serif;
}

html, body {
  height: 100vh;
  overflow:hidden;
}

* {
  -webkit-touch-callout: none;
  /* prevent callout to copy image, etc when tap to hold */
  -webkit-text-size-adjust: none;
  /* prevent webkit from resizing text to fit */
  /* make transparent link selection, adjust last value opacity 0 to 1.0 */
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  /* prevent copy paste, to allow, change 'none' to 'text' */
  -webkit-tap-highlight-color: transparent;
}

/* Give input back to Auth Form */
.AuthForm, .AuthForm * {
  -webkit-user-select: initial;
}
/* and the Set Member Name form */
.SetMemberNameBox, .SetMemberNameBox * {
  -webkit-user-select: initial;
}


.PauseMenu__hover__memberName:hover {
  background: #e8ecf1;
}


@-webkit-keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@-webkit-keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; }
}
@-webkit-keyframes blink {
  to { visibility: hidden }
}
@keyframes blink {
  to { visibility: hidden }
}

@-webkit-keyframes iconFloat {
  0% { -webkit-transform: translateY(-27%); transform: translateY(-27%); }
  100% { -webkit-transform: translateY(0%); transform: translateY(0%); }
}

@keyframes iconFloat {
  0% { -webkit-transform: translateY(-27%); transform: translateY(-27%); }
  100% { -webkit-transform: translateY(0%); transform: translateY(0%); }
}
canvas {
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-crisp-edges;
    image-rendering: pixelated;
    image-rendering: crisp-edges;
}
@-webkit-keyframes characterWalkDown {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }
}

@keyframes characterWalkDown {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
                transform: translate3d(-100%, 0, 0);
    }
}

@-webkit-keyframes characterWalkRight {
    0% {
        -webkit-transform: translate3d(0, -25%, 0);
                transform: translate3d(0, -25%, 0);
    }
    100% {
        -webkit-transform: translate3d(-100%, -25%, 0);
                transform: translate3d(-100%, -25%, 0);
    }
}

@keyframes characterWalkRight {
    0% {
        -webkit-transform: translate3d(0, -25%, 0);
                transform: translate3d(0, -25%, 0);
    }
    100% {
        -webkit-transform: translate3d(-100%, -25%, 0);
                transform: translate3d(-100%, -25%, 0);
    }
}

@-webkit-keyframes characterWalkUp {
    0% {
        -webkit-transform: translate3d(0, -50%, 0);
                transform: translate3d(0, -50%, 0);
    }
    100% {
        -webkit-transform: translate3d(-100%, -50%, 0);
                transform: translate3d(-100%, -50%, 0);
    }
}

@keyframes characterWalkUp {
    0% {
        -webkit-transform: translate3d(0, -50%, 0);
                transform: translate3d(0, -50%, 0);
    }
    100% {
        -webkit-transform: translate3d(-100%, -50%, 0);
                transform: translate3d(-100%, -50%, 0);
    }
}

@-webkit-keyframes characterWalkLeft {
    0% {
        -webkit-transform: translate3d(0, -75%, 0);
                transform: translate3d(0, -75%, 0);
    }
    100% {
        -webkit-transform: translate3d(-100%, -75%, 0);
                transform: translate3d(-100%, -75%, 0);
    }
}

@keyframes characterWalkLeft {
    0% {
        -webkit-transform: translate3d(0, -75%, 0);
                transform: translate3d(0, -75%, 0);
    }
    100% {
        -webkit-transform: translate3d(-100%, -75%, 0);
                transform: translate3d(-100%, -75%, 0);
    }
}

/* JUMP */
@-webkit-keyframes characterJump {
    /* NOTE: Add space at the end where nothing happens. This enables some time afterwards */
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    20% {
        -webkit-transform: translate3d(0, -20%, 0);
                transform: translate3d(0, -20%, 0);
    }
    50% {
        -webkit-transform: translate3d(0, -25%, 0);
                transform: translate3d(0, -25%, 0);
    }
    55% {
        -webkit-transform: translate3d(0, -27%, 0);
                transform: translate3d(0, -27%, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}
@keyframes characterJump {
    /* NOTE: Add space at the end where nothing happens. This enables some time afterwards */
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    20% {
        -webkit-transform: translate3d(0, -20%, 0);
                transform: translate3d(0, -20%, 0);
    }
    50% {
        -webkit-transform: translate3d(0, -25%, 0);
                transform: translate3d(0, -25%, 0);
    }
    55% {
        -webkit-transform: translate3d(0, -27%, 0);
                transform: translate3d(0, -27%, 0);
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
}

@-webkit-keyframes floatyIconBounce {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 12%, 0);
                transform: translate3d(0, 12%, 0);
    }
}

@keyframes floatyIconBounce {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0, 12%, 0);
                transform: translate3d(0, 12%, 0);
    }
}

@-webkit-keyframes hackerFlicker {
    /*0% {*/
        /*opacity: 1;*/
    /*}*/
    /*50% {*/
        /*opacity: 0*/
    /*}*/
}

@keyframes hackerFlicker {
    /*0% {*/
        /*opacity: 1;*/
    /*}*/
    /*50% {*/
        /*opacity: 0*/
    /*}*/
}


.mobile-dpad {
    position: fixed;
    width: 120px;
    height: 120px;
    pointer-events: none;
    /*z-index: 99;*/ /* Can we do this only when there are no interaction events live? */
    /* Maybe this rule should move to JS. Not sure I want a global body class */
}

.mobile-dpad.dpad-active .dot {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
}

.mobile-dpad .circle {
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 60px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    left: 50%;
    top: 50%;
    margin-left: -30px;
    margin-top: -30px;
    z-index: 12;
    pointer-events: none;
    opacity:0;
}

.mobile-dpad .hitbox {
    position: absolute;
    z-index: 11;
    width: 40px;
    height: 40px;
    pointer-events: initial;
}

.mobile-dpad .hitbox.hb-active {
    visibility: visible;
}

/* OLD WAY */
/*.mobile-dpad .hitbox.hb-active svg path {*/
    /*fill: rgba(255, 255, 255, 0.6);*/
/*}*/
.mobile-dpad .hitbox.hb-active svg .DpadArrow--Fill {
    stroke: rgba(255,255,255,0.6);
}





.mobile-dpad .hitbox.hb-up {
    top: 0;
    left: 50%;
    margin-left: -20px;
}

.mobile-dpad .hitbox.hb-up svg {
    left: 50%;
    -webkit-transform: rotate(180deg) translateX(50%);
    transform: rotate(180deg) translateX(50%);
}

.mobile-dpad .hitbox.hb-down {
    bottom: 0;
    left: 50%;
    margin-left: -20px;
}

.mobile-dpad .hitbox.hb-down svg {
    bottom: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.mobile-dpad .hitbox.hb-left {
    top: 50%;
    left: 0;
    margin-top: -20px;
}

.mobile-dpad .hitbox.hb-left svg {
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.mobile-dpad .hitbox.hb-right {
    top: 50%;
    right: 0;
    margin-top: -20px;
}

.mobile-dpad .hitbox.hb-right svg {
    right: 0;
    top: 50%;
    -webkit-transform: rotate(180deg) translateY(50%);
    transform: rotate(180deg) translateY(50%);
}

.mobile-dpad .hitbox svg {
    position: absolute;
}
.Letter {
    opacity: 0;
}
.Letter--revealed {
    opacity: 1;
    /*animation: letterShrink forwards 0.1s;*/
}

/*@keyframes letterShrink {*/
    /*!*from { transform: scale3d(1.5, 1.5, 1)}*!*/
    /*!*to { transform: scale3d(1, 1, 1)}*!*/
    /*from { opacity: 0; transform: translate3d(0, -25%, 0)}*/
    /*to { opacity: 1; transform: translate3d(0, 0, 0)}*/
/*}*/
@-webkit-keyframes battleResultsFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes battleResultsFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes battleTurnBlipAppears {
    from {
        -webkit-transform: scale3d(2, 2, 1);
                transform: scale3d(2, 2, 1);
        opacity: 0;
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
        opacity: 1;
    }
}

@keyframes battleTurnBlipAppears {
    from {
        -webkit-transform: scale3d(2, 2, 1);
                transform: scale3d(2, 2, 1);
        opacity: 0;
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
                transform: scale3d(1, 1, 1);
        opacity: 1;
    }
}


@-webkit-keyframes battleMessageEnter {
    from {
        opacity: 0;
        /*transform: scale3d(0,0,0)*/
    }
    to {
        opacity: 1;
        /*transform: scale3d(1,1,1)*/
    }
}


@keyframes battleMessageEnter {
    from {
        opacity: 0;
        /*transform: scale3d(0,0,0)*/
    }
    to {
        opacity: 1;
        /*transform: scale3d(1,1,1)*/
    }
}

@-webkit-keyframes battleBlippyRise {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,0,0);
                transform: translate3d(0,0,0);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(0,-120%,0);
                transform: translate3d(0,-120%,0);
    }
}

@keyframes battleBlippyRise {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(0,0,0);
                transform: translate3d(0,0,0);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: translate3d(0,-120%,0);
                transform: translate3d(0,-120%,0);
    }
}


/* JUMP: todo - watch out... this is copied over from perf-overworld repo. will be a duplicate when going back */
@-webkit-keyframes characterJump {

    /* NOTE: Add space at the end where nothing happens. This enables some time afterwards */
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0)
    }
    20% {
        -webkit-transform: translate3d(0, -20%, 0);
                transform: translate3d(0, -20%, 0)
    }
    50% {
        -webkit-transform: translate3d(0, -25%, 0);
                transform: translate3d(0, -25%, 0)
    }
    55% {
        -webkit-transform: translate3d(0, -27%, 0);
                transform: translate3d(0, -27%, 0)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0)
    }
}
@keyframes characterJump {

    /* NOTE: Add space at the end where nothing happens. This enables some time afterwards */
    0% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0)
    }
    20% {
        -webkit-transform: translate3d(0, -20%, 0);
                transform: translate3d(0, -20%, 0)
    }
    50% {
        -webkit-transform: translate3d(0, -25%, 0);
                transform: translate3d(0, -25%, 0)
    }
    55% {
        -webkit-transform: translate3d(0, -27%, 0);
                transform: translate3d(0, -27%, 0)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0)
    }
}
/* todo - anything between these lines are new */
@-webkit-keyframes characterShake {
    from, to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translate3d(-7%, 0, 0);
        transform: translate3d(-7%, 0, 0);
    }

    20%, 40%, 60%, 80% {
        -webkit-transform: translate3d(7%, 0, 0);
        transform: translate3d(7%, 0, 0);
    }
}
@keyframes characterShake {
    from, to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translate3d(-7%, 0, 0);
        transform: translate3d(-7%, 0, 0);
    }

    20%, 40%, 60%, 80% {
        -webkit-transform: translate3d(7%, 0, 0);
        transform: translate3d(7%, 0, 0);
    }
}

/* TADA */
@-webkit-keyframes characterTada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    10%, 20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%, 60%, 80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@keyframes characterTada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    10%, 20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%, 60%, 80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
/* todo - end new zone */
@-webkit-keyframes authRunningCharacter {
    0% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
    100% { -webkit-transform: translate3d(-100%,0,0); transform: translate3d(-100%,0,0); }
}

@keyframes authRunningCharacter {
    0% { -webkit-transform: translate3d(0,0,0); transform: translate3d(0,0,0); }
    100% { -webkit-transform: translate3d(-100%,0,0); transform: translate3d(-100%,0,0); }
}

input[type="email"]::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B7B7B7;
    opacity: 1; /* Firefox */
}

input[type="email"]::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B7B7B7;
    opacity: 1; /* Firefox */
}

input[type="email"]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #B7B7B7;
    opacity: 1; /* Firefox */
}
