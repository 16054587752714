@keyframes battleResultsFadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes battleTurnBlipAppears {
    from {
        transform: scale3d(2, 2, 1);
        opacity: 0;
    }
    to {
        transform: scale3d(1, 1, 1);
        opacity: 1;
    }
}


@keyframes battleMessageEnter {
    from {
        opacity: 0;
        /*transform: scale3d(0,0,0)*/
    }
    to {
        opacity: 1;
        /*transform: scale3d(1,1,1)*/
    }
}

@keyframes battleBlippyRise {
    0% {
        opacity: 0;
        transform: translate3d(0,0,0);
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translate3d(0,-120%,0);
    }
}


/* JUMP: todo - watch out... this is copied over from perf-overworld repo. will be a duplicate when going back */
@keyframes characterJump {

    /* NOTE: Add space at the end where nothing happens. This enables some time afterwards */
    0% {
        transform: translate3d(0, 0, 0)
    }
    20% {
        transform: translate3d(0, -20%, 0)
    }
    50% {
        transform: translate3d(0, -25%, 0)
    }
    55% {
        transform: translate3d(0, -27%, 0)
    }

    100% {
        transform: translate3d(0, 0, 0)
    }
}
/* todo - anything between these lines are new */
@keyframes characterShake {
    from, to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    10%, 30%, 50%, 70%, 90% {
        -webkit-transform: translate3d(-7%, 0, 0);
        transform: translate3d(-7%, 0, 0);
    }

    20%, 40%, 60%, 80% {
        -webkit-transform: translate3d(7%, 0, 0);
        transform: translate3d(7%, 0, 0);
    }
}

/* TADA */
@keyframes characterTada {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    10%, 20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }
    30%, 50%, 70%, 90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }
    40%, 60%, 80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }
    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
/* todo - end new zone */